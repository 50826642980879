setTimeout(function () {
  console.log("test")
  $(function () {
    if ($('.meetingInfo').length) {
      var meetingName = $.trim($('.meetingInfo').parent().find('h1').text());
      var meetingDate = $.trim($('.meetingInfo #meetingDate + .waarde').text());
      var meetingLocation = $.trim($('.meetingInfo #meetingLocation + .waarde').text());
      var meetingPlace = $.trim($('.meetingInfo #meetingAddress + .waarde .subveld').text());
      var meetingPlaceLength = meetingPlace.length;

      meetingPlace = meetingPlace.substring(8, meetingPlaceLength);

      meetingPlace = $.trim(meetingPlace);
      meetingPlace = meetingLocation + ' te ' + meetingPlace;

      $('#LIVITS_ID_4606077621579').val(meetingName);
      $('#LIVITS_ID_8906310636380').val(meetingDate);
      $('#LIVITS_ID_9261420329146').val(meetingPlace);
    }
  });
}, 1500);



$(function () {
  // break words manually
  var headings = [].slice.call(document.querySelectorAll('h1,h2,h3,h4,h5,h6'));
  if (headings) {
    replaceHyphens(headings);
  }
});

function replaceHyphens(elem) {
  for (var i = 0; i < elem.length; i++) {
    var string = elem[i].innerHTML;
    string = string.replace(/&amp;shy;/g, '&shy;');
    elem[i].innerHTML = string;
  }
}